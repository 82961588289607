import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MovingIcon from "@mui/icons-material/Moving";
import GradeIcon from "@mui/icons-material/Grade";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CustomizedTimeline from "../components/CustomizedTimeline";
import AccordionComponent from "../components/Accordion";
import "./index.css";

export default function MainPage() {
  return (
    <div style={{ backgroundColor: "#FCFCFC" }}>
      <div className="main_block">
        <div style={{ display: "flex" }}>
          <div className="main_text">
            <div>
              <h1
                style={{
                  fontSize: "40px",
                  fontWeight: "bolder",
                  letterSpacing: "3px",
                }}
              >
                MOGAME
              </h1>
              <div>-</div>
              <h1 style={{ letterSpacing: "5px" }}>
                Создавай свой уникальный контент
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "50px",
        }}
      >
        <div
          style={{
            maxWidth: "1400px",
            width: "100%",
            padding: "40px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              gap: "50px",
            }}
          >
            <div className="column_list_item" style={{ width: "500px" }}>
              <div className="subtitle">
                <h2>Сервис для создания игро-фильмов для телеграма</h2>
              </div>
            </div>

            <div
              style={{
                border: "2px solid #305478",
                padding: "10px 10px",
                backgroundColor: "white",
                maxWidth: "650px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="table_item">x</div>
                <div className="table_item">x</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  <div className="column_list_item">
                    <ul style={{ listStyle: "none" }}>
                      <li className="list_item">Уникальный контент</li>
                    </ul>
                    <div className="subtitle">
                      <GradeIcon className="icons" />
                    </div>
                  </div>
                </div>
                <div className="column_list_item">
                  <ul style={{ listStyle: "none" }}>
                    <li className="list_item">Новые подписчики</li>
                  </ul>
                  <div className="subtitle">
                    <MovingIcon className="icons" />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="/images/tg.png"
                  alt="tg-pic"
                  style={{
                    width: "300px",
                    height: "200px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
              >
                <div className="column_list_item">
                  <div className="subtitle">
                    <AttachMoneyIcon className="icons" />
                  </div>

                  <ul style={{ listStyle: "none" }}>
                    <li className="list_item">Монетизация</li>
                  </ul>
                </div>
                <div className="column_list_item">
                  <div className="subtitle">
                    <SupportAgentIcon className="icons" />
                  </div>

                  <ul style={{ listStyle: "none" }}>
                    <li className="list_item">Поддержка</li>
                  </ul>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="table_item">x</div>
                <div className="table_item">x</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div>
          <div className="title">
            <h2>Создавай свои сценарии и пусть пользователи пройдут по нему</h2>
          </div>

          <div
            style={{
              display: "flex",
              gap: "40px",
              flexWrap: "wrap",
              justifyContent: "center",
              padding: "25px 0",
            }}
          >
            <div className="column_list">
              <video
                controls
                style={{ width: "100%", maxWidth: "500px", height: "300px" }}
              >
                <source src="/images/flowers-3.mp4" type="video/webm" />
              </video>
            </div>
          </div>
          <div className="column_list">
            <CustomizedTimeline />
          </div>
        </div>
      </div>

      <div className="block" style={{ backgroundColor: "#F4F4F4" }}>
        <div
          style={{
            display: "flex",
            padding: "20px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div>
              <div style={{ textAlign: "center", padding: "10px 0" }}>
                <div className="title">
                  <h2>Создавать это просто</h2>
                </div>
              </div>
              <img
                src="/images/tg1.png"
                alt="tg-pic"
                style={{
                  width: "100%",
                  maxWidth: "1000px",
                  maxHeight: "715px",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <img
              src="/images/tg_main.png"
              alt="tg-pic"
              style={{
                width: "100%",
                maxWidth: "600px",
                maxHeight: "715px",
              }}
            />
          </div>
        </div>
      </div>

      <div className="block">
        <div>
          <div className="title">
            <h2>FAQ</h2>
          </div>
          <AccordionComponent />
        </div>
      </div>
      <div className="footer"></div>
    </div>
  );
}
