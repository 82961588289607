import { createAsyncThunk } from "@reduxjs/toolkit";

const prodURL = "/api";
const url =
  window.location.hostname === "localhost"
    ? "http://localhost:3004/api"
    : prodURL;

export const registrationUser = createAsyncThunk(
  "user/register",
  async function (props) {
    const { login, password, key } = props;

    const response = await fetch(`${url}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login,
        password,
        key,
      }),
    });

    const result = await response.text();
    return JSON.parse(result);
  }
);

export const loginUser = createAsyncThunk("user/login", async function (props) {
  const { login, password } = props;

  const response = await fetch(`${url}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      login,
      password,
    }),
  });

  const result = await response.text();
  const parseResult = JSON.parse(result);

  return parseResult;
});

export const getGame = createAsyncThunk(`user/getGame`, async function (props) {
  const { gameId } = props;

  const response = await fetch(`${url}/game/${gameId}`);

  const game = await response.text();

  return JSON.parse(game);
});

export const addNewGame = createAsyncThunk(
  "user/addNewGame",
  async function (props) {
    const { userId, textProject, onSuccess } = props;

    const response = await fetch(`${url}/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        textProject,
      }),
    });

    const result = await response.text();

    onSuccess?.();

    return JSON.parse(result);
  }
);

export const deleteGame = createAsyncThunk(
  "user/deleteGame",
  async function (props) {
    const { userId, id, onSucces } = props;

    const response = await fetch(`${url}/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
      }),
    });

    const result = await response.text();

    if (result) {
      onSucces?.();

      return JSON.parse(result);
    }
  }
);

export const saveGame = createAsyncThunk(
  "user/saveGame",
  async function (props) {
    const { selectedGameId, nodes, edges } = props;

    const response = await fetch(`${url}/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          selectedGameId,
          nodes,
          edges,
        },
      }),
    });

    const result = await response.text();
    return JSON.parse(result);
  }
);

export const saveGameSettings = createAsyncThunk(
  "user/saveGameSettings",
  async function (props) {
    const { game, title, description, category, settings, onSucces } = props;

    const response = await fetch(`${url}/project-settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          id: game,
          title,
          description,
          category,
          settings,
        },
      }),
    });

    onSucces?.();

    const result = await response.text();
    return JSON.parse(result);
  }
);

export const getLinks = createAsyncThunk(
  "user/getLinks",
  async function (props) {
    const { userId } = props;

    const response = await fetch(`${url}/links`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          userId,
        },
      }),
    });

    const result = await response.text();
    return JSON.parse(result);
  }
);

export const loadVideoScene = createAsyncThunk(
  `user/addVideoScene`,
  async function (props) {
    const { userId, selectedGameId, scene, file, game } = props;

    const formData = new FormData();

    formData.append("videoFile", file);
    formData.append("gameData", JSON.stringify(game));

    const response = await fetch(
      `${url}/upload/${userId}/${selectedGameId}/${scene}`,
      {
        method: "POST",
        body: formData,
      }
    );

    const result = await response.text();

    return JSON.parse(result);
  }
);

export const loadPoster = createAsyncThunk(
  `user/loadPoster`,
  async function (props) {
    const { gameId, base64String, onSucces } = props;

    const formData = new FormData();
    formData.append("imageFile", base64String);

    const response = await fetch(`${url}/upload/${gameId}`, {
      method: "POST",
      body: formData,
    });

    const result = await response.text();

    if (result) {
      onSucces?.();

      return JSON.parse(result);
    }
  }
);

export const deletePoster = createAsyncThunk(
  "user/deletePoster",
  async function (props) {
    const { gameId, onSucces } = props;

    const response = await fetch(`${url}/deletePoster/${gameId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.text();

    if (result) {
      onSucces?.();

      return JSON.parse(result);
    }
  }
);

export const getVideo = createAsyncThunk(
  `user/getVideo`,
  async function (props) {
    const { selectedGameId } = props;

    const response = await fetch(`${url}/video/${selectedGameId}`);

    const result = await response.text();

    return JSON.parse(result);
  }
);

export const getNotifications = createAsyncThunk(
  "user/getNotifications",
  async function (props) {
    const { userId } = props;

    const response = await fetch(`${url}/notifications`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          userId,
        },
      }),
    });

    const result = await response.text();
    return JSON.parse(result);
  }
);

export const addDescription = createAsyncThunk(
  "user/addDescription",
  async function (props) {
    const { selectedGameId, description } = props;

    const response = await fetch(`${url}/description`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          selectedGameId,
          description,
        },
      }),
    });

    const result = await response.text();
    return JSON.parse(result);
  }
);

export const generateKey = createAsyncThunk(
  "user/generateKey",
  async function (props) {
    const { game, user } = props;

    const response = await fetch(`${url}/generate-key`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user,
        game,
      }),
    });

    const result = await response.text();

    return JSON.parse(result);
  }
);

export const getHelpMessage = createAsyncThunk(
  "user/getHelpMessage",
  async function (props) {
    const { key } = props;

    const response = await fetch(`${url}/help`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: key,
      }),
    });

    const result = await response.text();

    return JSON.parse(result);
  }
);

export const getRules = createAsyncThunk(`user/getRules`, async function () {
  const response = await fetch(`${url}/rules`);

  const result = await response.text();

  return JSON.parse(result);
});

export const getInfo = createAsyncThunk(`user/getInfo`, async function () {
  const response = await fetch(`${url}/info`);

  const result = await response.text();

  return JSON.parse(result);
});

export const confirmEmailUser = createAsyncThunk(
  "user/email",
  async function (props) {
    const { id, email } = props;

    const response = await fetch(`${url}/profile/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        email,
      }),
    });

    const result = await response.text();
    const parseResult = JSON.parse(result);

    return parseResult;
  }
);

export const confirmCodeEmail = createAsyncThunk(
  "user/confirmCodeEmail",
  async function (props) {
    const { id, code } = props;

    const response = await fetch(`${url}/profile/confirm-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        code,
      }),
    });

    const result = await response.text();
    const parseResult = JSON.parse(result);

    return parseResult;
  }
);

export const confirmBirthday = createAsyncThunk(
  "user/birthday",
  async function (props) {
    const { id, date } = props;

    const response = await fetch(`${url}/profile/birthday`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        date,
      }),
    });

    const result = await response.text();
    const parseResult = JSON.parse(result);

    return parseResult;
  }
);

export const getGamesForModerator = createAsyncThunk(
  "user/getGamesForModerator",
  async function (props) {
    const { id } = props;

    const response = await fetch(`${url}/moderator/games`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    });

    const result = await response.text();
    const parseResult = JSON.parse(result);

    return parseResult;
  }
);

export const getUsersForModerator = createAsyncThunk(
  "user/getUsersForModerator",
  async function (props) {
    const { id } = props;

    const response = await fetch(`${url}/moderator/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    });

    const result = await response.text();
    const parseResult = JSON.parse(result);

    return parseResult;
  }
);

export const postNews = createAsyncThunk(
  "user/postNews",
  async function (props) {
    const { id, description } = props;

    const response = await fetch(`${url}/moderator/news`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        description,
      }),
    });

    const result = await response.text();
    const parseResult = JSON.parse(result);

    return parseResult;
  }
);

export const getInstruction = createAsyncThunk(`user/getInstruction`, async function () {
  const response = await fetch(`${url}/instruction`);

  const game = await response.text();

  return JSON.parse(game);
});