import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Tooltip,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import {
  getHelpMessage,
  confirmEmailUser,
  confirmBirthday,
  confirmCodeEmail,
} from "../redux/asyncThunk/userActions";
import ErrorIcon from "@mui/icons-material/Error";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { days, months } from "../utils/constants";
import "../index.css";

export default function Profile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [codeEmail, setCodeEmail] = useState("");
  const [date, setDate] = useState({
    day: "01",
    month: "Январь",
    year: "2024",
  });

  useEffect(() => {
    if (user?.data?.birthday) {
      const day = user?.data?.birthday.split("/")[0];
      const month = user?.data?.birthday.split("/")[1];
      const year = user?.data?.birthday.split("/")[2];

      if (day && month && year) {
        setDate({
          day,
          month,
          year,
        });
      }
    }
  }, [user?.data?.birthday]);

  const handleChange = (event) => {
    setDate((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  let years = [];
  const getYearsList = () => {
    let currentYear = new Date().getFullYear();

    for (let i = 0; i < 100; i++) {
      const element = currentYear--;
      years.push(element);
    }
  };

  getYearsList();

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "10px 0" }}
      >
        <h1>Профиль</h1>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "10px 10px",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div>ID</div>
            <div>{user.data._id}</div>
          </div>
          <div style={{ display: "flex", gap: "10px", alignItems: "cneter" }}>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <div>Электронная почта:</div>
              {user?.data?.email?.adress && !user?.data?.email?.confirm ? (
                <Tooltip
                  title="Нажмите для получения подсказки"
                  placement="top"
                >
                  <ErrorIcon
                    onClick={() => dispatch(getHelpMessage({ key: "email" }))}
                    sx={{ cursor: "pointer", fontSize: "14px", color: "red" }}
                  />
                </Tooltip>
              ) : null}
              {user?.data?.email?.adress ? (
                <Typography
                  size="small"
                  id="outlined-basic"
                  label="Почта"
                  variant="outlined"
                >
                  {user?.data?.email?.adress}
                </Typography>
              ) : null}
              {/* {user?.data?.email?.adress ? (
                <Tooltip title="Изменить почту" placement="top">
                  <EditRoundedIcon
                    sx={{ cursor: "pointer", fontSize: "18px" }}
                  />
                </Tooltip>
              ) : null} */}
            </div>

            {!user?.data?.email?.adress ? (
              <>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Почта"
                  variant="outlined"
                  onChange={(event) => setEmail(event.target.value)}
                />
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    dispatch(
                      confirmEmailUser({
                        id: user.data._id,
                        email: email,
                      })
                    );
                  }}
                >
                  Добавить почту
                </Button>
              </>
            ) : null}
            {user?.data?.email?.adress &&
            !user?.data?.email?.confirm &&
            user?.data?.email?.confirmCode ? (
              <>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Код подтверждения"
                  variant="outlined"
                  onChange={(event) => setCodeEmail(event.target.value)}
                />
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    dispatch(
                      confirmCodeEmail({
                        id: user.data._id,
                        code: codeEmail,
                      })
                    );
                  }}
                >
                  Подтвердить почту
                </Button>
              </>
            ) : null}
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <div>Привязка аккаунта к телеграм аккаунту</div>
              <Tooltip title="Нажмите для получения подсказки" placement="top">
                <ErrorIcon
                  onClick={() =>
                    dispatch(getHelpMessage({ key: "linkToTGaccaunt" }))
                  }
                  sx={{ cursor: "pointer", fontSize: "14px", color: "red" }}
                />
              </Tooltip>
            </div>
            {!user.data.tgId ? (
              <>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Почта"
                  variant="outlined"
                />
                <Button size="small" variant="contained">
                  Привязать аккаунт
                </Button>
              </>
            ) : null}
          </div>

          <div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <div>Подтверждение возраста:</div>
                {!user.data.birthday ? (
                  <Tooltip
                    title="Нажмите для получения подсказки"
                    placement="top"
                  >
                    <ErrorIcon
                      onClick={() =>
                        dispatch(getHelpMessage({ key: "agesConfirm" }))
                      }
                      sx={{ cursor: "pointer", fontSize: "14px", color: "red" }}
                    />
                  </Tooltip>
                ) : (
                  <div>{user.data.birthday}</div>
                )}
              </div>
            </div>

            {!user.data.birthday ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  marginTop: "15px",
                  flexDirection: "column",
                }}
              >
                <Typography fontSize={"12px"}>Дата рождения</Typography>
                <Box
                  sx={{ display: "flex", gap: "15px", alignItems: "center" }}
                >
                  <FormControl size="small" sx={{ width: "80px" }}>
                    <InputLabel id="demo-simple-select-label">День</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="day-simple-select"
                      value={date.day}
                      label="День"
                      name="day"
                      onChange={handleChange}
                      disabled={user.data.birthday ? true : false}
                    >
                      {days.map((el, i) => {
                        return (
                          <MenuItem value={el} key={i + el}>
                            {el}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl size="small" sx={{ width: "140px" }}>
                    <InputLabel id="demo-simple-select-label">Месяц</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="month-simple-select"
                      name="month"
                      value={date.month}
                      label="Месяц"
                      onChange={handleChange}
                      disabled={user.data.birthday ? true : false}
                    >
                      {months.map((el, i) => {
                        return (
                          <MenuItem value={el} key={i + el}>
                            {el}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl size="small" sx={{ width: "100px" }}>
                    <InputLabel id="demo-simple-select-label">Год</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="month-simple-select"
                      value={date.year}
                      label="Год"
                      name="year"
                      onChange={handleChange}
                      disabled={user.data.birthday ? true : false}
                    >
                      {years.map((el, i) => {
                        return (
                          <MenuItem value={el} key={i + el}>
                            {el}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {user.data.birthday ? (
                    <Tooltip title="Изменить дату рождения" placement="top">
                      <EditRoundedIcon sx={{ cursor: "pointer" }} />
                    </Tooltip>
                  ) : null}
                </Box>
                {!user.data.birthday ? (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ width: "300px" }}
                    onClick={() =>
                      dispatch(confirmBirthday({ id: user.data._id, date }))
                    }
                  >
                    Подтвердить
                  </Button>
                ) : null}
              </Box>
            ) : null}
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "15px",
            }}
          >
            <div style={{ fontWeight: 700, fontSize: "20px" }}>
              Доступные ключи:
            </div>
            <div
              style={{
                border: "1px solid black",
                padding: "20px 15px",
                fontSize: "26px",
                color: "white",
                backgroundColor: user?.data?.limit < 5 ? "red" : "green",
              }}
            >
              {user?.data?.limit}
            </div>
          </div>

          <div style={{ textAlign: "center", padding: "10px 0" }}>
            Ключи по всем сценариям
          </div>

          <ul>
            <div
              style={{
                display: "flex",
                gap: "3px",
                backgroundColor: "#b6c3cf",
                maxWidth: "1000px",
                width: "100%",
                justifyContent: "space-between",
                height: "40px",
                alignItems: "center",
              }}
            >
              <div style={{ width: "200px", textAlign: "center" }}>Проект</div>
              <div style={{ width: "400px", textAlign: "center" }}>Ключ</div>
              <div style={{ width: "200px", textAlign: "center" }}>
                Использован
              </div>
              <div style={{ width: "200px", textAlign: "center" }}>
                Дата использования
              </div>
            </div>
            {user?.data?.keys?.map((el) => {
              const getTitle = user?.games?.find(
                (elem) => el.game === elem._id
              );

              return (
                <li style={{ display: "flex", marginTop: "7px" }} key={el.key}>
                  <div style={{ width: "200px", textAlign: "center" }}>
                    {getTitle?.title}
                  </div>
                  <div style={{ width: "400px", textAlign: "center" }}>
                    {el.key}
                  </div>
                  <div style={{ width: "200px", textAlign: "center" }}>
                    {el.availeble ? "Нет" : "Да"}
                  </div>
                  <div style={{ width: "200px", textAlign: "center" }}>
                    {el.usageDate ? el.usageDate : "Не использован"}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
