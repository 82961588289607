import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TelegramChat from "./TelegramChat";
import ClearIcon from "@mui/icons-material/Clear";
import { userSlice } from "../redux/user/userSlice";
import { useLocation } from "react-router-dom";
// import { getVideo } from "../redux/asyncThunk/userActions";
import io from "socket.io-client";

const { clearMessages, addMessage } = userSlice;

const prodURL = "https://club-lab.ru";
const url =
  window.location.hostname === "localhost" ? "http://localhost:3005" : prodURL;

const socket = io(url, {
  path: "/socket.io",
  transports: ["websocket"],
  secure: true,
});

export default function TelegramView() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeButton, setActiveButton] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [buttonsData, setButtonsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const selectedGameId = useSelector((state) => state.user?.selectedGameId);
  const user = useSelector((state) => state.user.data);

  const handleActive = () => {
    setActiveButton(!activeButton);
  };

  const handleLeave = () => {
    setActiveButton(activeButton);
  };

  const activeStyle = {
    backgroundColor: "white",
    color: "#272542",
  };

  useEffect(() => {
    setVideoSrc(null);
    setButtonsData(null);
  }, [location.pathname]);

  // Переписать с использованием p2p
  useEffect(() => {
    const handleFileData = (data) => {
      const { fileData, buttons } = data;
      const blob = new Blob([fileData], { type: "video/mp4" });
      const url = URL.createObjectURL(blob);
      setIsLoading(false);
      setVideoSrc(url);
      setButtonsData(buttons);
    };

    socket.on("fileData", handleFileData);
    socket.on("error", (message) => {
      console.error("Error:", message);
    });

    return () => {
      socket.off("fileData", handleFileData);
      socket.off("error");
    };
  }, []);

  const handleRequestFile = (scene) => {
    if (!selectedGameId) {
      dispatch(
        addMessage({
          type: "error",
          message:
            "Чтобы запустить сценарий, необходимо перейти в него и после этого нажать - Запустить",
        })
      );
    } else {
      setVideoSrc(null);
      setIsLoading(true);
      socket.emit("requestFile", {
        userId: user._id,
        projectId: selectedGameId,
        scene,
      });
    }
  };

  return (
    <div className="tgContainer">
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0 5px",
          }}
        >
          <div style={{ padding: "20px 20px", color: "white" }}>
            <ClearIcon
              sx={{ cursor: "pointer" }}
              onClick={() => dispatch(clearMessages())}
            />
          </div>
        </div>
      </div>

      <TelegramChat
        selectedGameId={selectedGameId}
        handleRequestFile={handleRequestFile}
        videoSrc={videoSrc}
        buttonsData={buttonsData}
        isLoading={isLoading}
      />

      <div
        style={activeButton ? activeStyle : null}
        className="tgButton"
        onMouseDown={handleActive}
        onMouseUp={handleActive}
        onMouseLeave={handleLeave}
        onClick={() => handleRequestFile(1)}
      >
        <div>Запустить</div>
      </div>
    </div>
  );
}
