import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NodeToolbar, Handle, Position } from "reactflow";
import { loadVideoScene, saveGame } from "../../redux/asyncThunk/userActions";
import "reactflow/dist/style.css";
import "../index.css";
import { userSlice } from "../../redux/user/userSlice";

import NodeDeleteContext from "./NodeDeleteContext";

import { Box, TextField, Button } from "@mui/material";

const { addMessage } = userSlice;

const prodURL = "https://club-lab.ru";
const url =
  window.location.hostname === "localhost" ? "http://localhost:3004" : prodURL;

export function NodeWithToolbar(props) {
  const { data, isConnectable, id } = props;
  const { onNodesDelete, nodes, edges } = useContext(NodeDeleteContext);

  const dispatch = useDispatch();
  const selectedGameId = useSelector((state) => state.user?.selectedGameId);
  const game = useSelector((state) => state.user?.game);
  const user = useSelector((state) => state.user?.data);

  const [buttonText, setButtonText] = useState("");
  const [action, setAction] = useState("");
  const [screenshot, setScreenshot] = useState(null);

  useEffect(() => {
    if (url && data?.screenpath) {
      const updatePicPath =
        url + "/" + data?.screenpath + `?timestamp=${new Date().getTime()}`;

      setScreenshot(updatePicPath);
    }
  }, [data]);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];

    dispatch(
      loadVideoScene({
        userId: user._id,
        selectedGameId,
        scene: id,
        file,
        game,
      })
    );
  };

  const handleAddButtonNode = () => {
    if (!buttonText) {
      return dispatch(
        addMessage({
          type: "error",
          message: "Кнопка не может быть без текста",
        })
      );
    }

    const checkButtonsLength = game.nodes.filter(
      (el) => el.id.split("-")[0] === id && el.type === "input"
    );

    if (checkButtonsLength.length === 2) {
      return dispatch(
        addMessage({
          type: "error",
          message: "Сейчас вы можете добавить только две кнопки",
        })
      );
    }

    let busyPosition;

    if (checkButtonsLength?.length) {
      if (checkButtonsLength[0].data.buttonPosiiton === "top") {
        busyPosition = "bottom";
      } else {
        busyPosition = "top";
      }
    } else {
      busyPosition = "bottom";
    }

    const buttonPosiiton = {
      x: 10,
      y: 0,
    };

    const checkButtonsInNode = game.nodes.find(
      (el) => el.id.split("-")[0] === id && el.type === "input"
    );

    const newId = !checkButtonsInNode
      ? `${id}-${Number(id) + 1}`
      : `${id}-${Number(checkButtonsInNode.id.split("-")[1]) + 1}`;

    if (busyPosition === "bottom") {
      buttonPosiiton.y = 128;
    } else {
      buttonPosiiton.y = 110;
    }

    const newButtonNode = {
      id: newId,
      data: { label: buttonText, buttonPosiiton: busyPosition },
      position: buttonPosiiton,
      style: {
        width: 140,
        height: 15,
      },
      parentId: `${id}-group`,
      sourcePosition: "right",
      type: "input",
      extent: "parent",
      parentNode: `${id}-group`,
      draggable: false,
    };

    dispatch(
      saveGame({
        selectedGameId,
        nodes: [...nodes, newButtonNode],
        edges: edges,
      })
    );
  };

  return (
    <>
      <NodeToolbar
        isVisible={data?.forceToolbarVisible || undefined}
        position={data?.toolbarPosition}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            marginBottom: "10px",
          }}
        >
          {action === "button" ? (
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <TextField
                sx={{ bgcolor: "white" }}
                id="outlined-basic"
                label="Текст кнопки"
                variant="outlined"
                size="small"
                onChange={(event) => setButtonText(event.target.value)}
              />
              <Button variant="contained" onClick={() => handleAddButtonNode()}>
                Добавить
              </Button>
              {/* <Button variant="contained" onClick={() => onNodesDelete()}>
                Удалить ноду
              </Button> */}
            </Box>
          ) : null}
          {action === "video" ? (
            <input
              type="file"
              name="videoFile"
              accept="video/*"
              onChange={handleVideoChange}
            />
          ) : null}
          <Box sx={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                className="toolbar_button"
                onClick={() => setAction("video")}
              >
                Добавить видео
              </button>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <button
                className="toolbar_button"
                onClick={() => setAction("button")}
              >
                Добавить кнопку
              </button>
            </div>
          </Box>
        </Box>
      </NodeToolbar>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div
          style={{
            height: "140px",
            border: "1px solid #b6c3cf",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#2F5478",
            width: "138px",
          }}
        >
          {screenshot ? (
            <img
              src={`${url}/${
                data?.screenpath
              }?timestamp=${new Date().getTime()}`}
              alt={`Screenshot`}
              style={{ width: "200px", height: "100px", objectFit: "contain" }}
            />
          ) : (
            "Нажмите в эту область для действия"
          )}
        </div>
      </div>
    </>
  );
}
