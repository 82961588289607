import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import "../index.css";

const prodURL = "https://club-lab.ru";
const url =
  window.location.hostname === "localhost" ? "http://localhost:3004" : prodURL;

export default function Instruction() {
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState(null);
  const instructions = useSelector((state) => state.user?.instructions);

  useEffect(() => {
    if (instructions) {
      const getPage = instructions.find((el) => el.step === page);

      if (getPage) {
        setPageData(getPage);
      }
    }
  }, [instructions, page]);

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "10px 0" }}
      >
        <h1>Обучение</h1>
      </div>

      {pageData ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            marginTop: "25px",
          }}
        >
          <div>
            <h3>Шаг: {pageData.step}</h3>
          </div>
          <div>{pageData.description}</div>

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              maxWidth: "1740px",
              width: "100%",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-around",
              height: "70vh",
              backgroundColor: "#efefef",
            }}
          >
            {pageData.content?.map((el) => {
              return (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={`${url}/${
                        el.imgPath
                      }?timestamp=${new Date().getTime()}`}
                      alt={`Screenshot`}
                      style={{
                        maxWidth: `${
                          pageData.content.length > 2 ? "350px" : "850px"
                        }`,
                        width: "100%",
                        height: "100%",
                        maxHeight: "500px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        maxWidth: "500px",
                        width: "100%",
                      }}
                    >
                      {el.text}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          <div style={{ display: "flex", gap: "20px", marginTop: "25px" }}>
            {page > 1 ? (
              <Button variant="contained" onClick={() => setPage(page - 1)}>
                Назад
              </Button>
            ) : null}
            <Button variant="contained" onClick={() => setPage(page + 1)}>
              Дальше
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
