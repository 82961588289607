import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const telegramUsername = "/MoGame_Support";
const telegramLink = `https://t.me/${telegramUsername}`;

export default function AccordionComponent() {
  return (
    <div style={{ maxWidth: "800px", width: "100%" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Политика конфиденциальности
        </AccordionSummary>
        <AccordionDetails>
          Сервис не собирает и не хранит ваши персональные данные, за
          исключением необходимого набора заполненных вами полей, которые
          необходимы для корректного функционирования сервиса
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Что означает - сервис находится на этапе тестирования?
        </AccordionSummary>
        <AccordionDetails>
          Сервис находится на этапе тестирования. Основной функционал работает
          корректно, но возможны переиодические сбои. Мы постараемся не
          допускать этого, но просим с пониманием отнестись к возникающим
          ошибкам и по возможности сообщать о них в службу поддержки.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Сервис бесплатный?
        </AccordionSummary>
        <AccordionDetails>
          На этапе тестирования весь функционал является бесплатным. После
          перехода сервиса на стабильную работу, часть функционала станет
          платной.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Что делать при возникновении трудностей при работе с сервисом?
        </AccordionSummary>
        <AccordionDetails>
          По любым возникающим вопросам вы можете{" "}
          <a href={telegramLink} target="_blank" rel="noopener noreferrer">
            обратиться в поддержку
          </a>
          . Взаимодейтствие с поддержкой осуществляется в телеграм. Специалисты
          обрабатывают обращения с 9 до 20 часов по московскому времени
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Можно ли связаться с командой сервиса?
        </AccordionSummary>
        <AccordionDetails>
          Да, вы можете{" "}
          <a href={telegramLink} target="_blank" rel="noopener noreferrer">
            обратиться в поддержку
          </a>
          . Абсолютно все обращения рассматриваются и команда сервиса
          обязательно ответит вам.
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
