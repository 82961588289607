import "./App.css";
import Button from "@mui/material/Button";
import MainPage from "./pages/MainPage";
import PersonalPage from "./pages/PersonalPage";
import Login from "./pages/LoginPage";
import { Routes, Route, Link, Outlet, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { userSlice } from "./redux/user/userSlice";
import PrivateRoute from "./components/PrivateRoute";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Tooltip } from "@mui/material";
// import TelegramAuth from "./components/TelegramLoginButton.js";

const { exitHandle, resetSelectedProject, showNotifications } = userSlice;

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Login />} />
        <Route
          path="/main/*"
          element={
            <PrivateRoute>
              <PersonalPage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  const telegramUsername = "/MoGame_Support";
  const telegramLink = `https://t.me/${telegramUsername}`;

  return (
    <div>
      <header className="header">
        <div style={{ maxWidth: "200px", width: "100%", textAlign: "center" }}>
          <Link
            to={user ? "/main" : "/"}
            onClick={() => dispatch(resetSelectedProject())}
            style={{ textDecoration: "none" }}
          >
            <span
              style={{
                cursor: "pointer",
                color: "white",
                fontWeight: "bolder",
                fontSize: "18px",
              }}
            >
              MOGAME
            </span>
          </Link>
        </div>

        {!user?._id ? (
          <div
            style={{
              backgroundColor: "yellow",
              padding: "7px 5px",
              border: "1px solid gray",
              position: "absolute",
              zIndex: "2",
              top: 50,
              textAlign: "center",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            Сервис находится на этапе тестирования. При возникновении вопросов
            вы можете обратиться в поддержку
          </div>
        ) : null}

        <div
          style={{
            maxWidth: "250px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {user?._id ? (
            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Tooltip title="Написать в поддержку" placement="left">
                  <div className="support">
                    <a
                      href={telegramLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SupportAgentIcon sx={{ color: "white" }} />
                    </a>
                  </div>
                </Tooltip>
              </div>
              <div
                style={{ position: "relative", cursor: "pointer" }}
                onClick={() => dispatch(showNotifications())}
              >
                <NotificationsIcon sx={{ color: "white" }} />
                {user.notifications.length ? (
                  <div
                    style={{
                      position: "absolute",
                      borderRadius: "50%",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "red",
                      top: 2,
                      right: 2,
                    }}
                  ></div>
                ) : null}
              </div>
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  borderBottom: "1px solid white",
                }}
              >
                <Link
                  to={"/main/profile"}
                  onClick={() => dispatch(resetSelectedProject())}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {user.login}
                </Link>
              </div>
              <LogoutIcon
                sx={{ cursor: "pointer", color: "white" }}
                onClick={() => dispatch(exitHandle())}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Tooltip title="Написать в поддержку" placement="left">
                <div className="support">
                  <a
                    href={telegramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SupportAgentIcon sx={{ color: "white" }} />
                  </a>
                </div>
              </Tooltip>
              <Link to={"/login"}>
                <Button
                  sx={{ bgcolor: "white" }}
                  variant="outlined"
                  size="small"
                >
                  Войти
                </Button>
              </Link>
              <Link to={"/registration"}>
                <Button
                  sx={{ bgcolor: "white" }}
                  variant="outlined"
                  size="small"
                >
                  Регистрация
                </Button>
              </Link>

              {/* <TelegramAuth
              // botName={name}
              // buttonSize="large" // "large" | "medium" | "small"
              // cornerRadius={3} // 0 - 20
              // usePic={false} // true | false
              // dataOnauth={handleBot}
              /> */}
            </div>
          )}
        </div>
      </header>

      <Outlet />
    </div>
  );
}

export default App;
