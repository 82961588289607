import { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
  ReactFlowProvider,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  useReactFlow,
} from "reactflow";
import { userSlice } from "../redux/user/userSlice";
import { Button, CircularProgress } from "@mui/material";
import { NodeWithToolbar } from "./nodes/NodeWithToolBar";
import { FirstSceneNode } from "./nodes/FirstSceneNode";
import "reactflow/dist/style.css";
import "./index.css";
import { useLocation } from "react-router-dom";
import Settings from "./Settings";
import { saveGame } from "../redux/asyncThunk/userActions";

import NodeDeleteContext from "./nodes/NodeDeleteContext";

const { showDialog, addMessage } = userSlice;

const nodeTypes = {
  "first-scene-node": FirstSceneNode,
  "node-with-toolbar": NodeWithToolbar,
};

const Flow = (props) => {
  const { game } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state) => state.user?.status);
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const reactFlowInstance = useRef(null);
  const { screenToFlowPosition } = useReactFlow();

  const { type } = location.state || {};

  const [viewMode, setViewMode] = useState("scheme");
  const [nodes, setNodes, onNodesChange] = useNodesState(game?.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(game?.edges);

  useEffect(() => {
    if (type === "settings") {
      setViewMode(type);
    }
  }, [type]);

  useEffect(() => {
    setNodes(game?.nodes);
    setEdges(game?.edges);
  }, [game, setEdges, setNodes]);

  const onConnect = useCallback(
    (connection) => {
      const checkEdges = edges.filter((el) => el.source === connection.source);

      if (!checkEdges.length) {
        dispatch(
          saveGame({
            selectedGameId: game?._id,
            nodes: [...nodes],
            edges: [
              ...edges,
              {
                id: connection.source,
                source: connection.source,
                target: connection.target,
                type: "step",
              },
            ],
          })
        );
      } else {
        dispatch(
          addMessage({
            type: "error",
            message: "Кнопка может вести только к одной сцене",
          })
        );
      }
    },
    [dispatch, edges, game?._id, nodes]
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        let newId = Date.now();

        const strID = newId.toString();

        const { clientX, clientY } = event;
        const position = screenToFlowPosition({ x: clientX, y: clientY });

        const groupNode = {
          id: `${strID}-group`,
          type: "group",
          position: {
            x: position.x,
            y: position.y,
          },
          style: {
            backgroundColor: "rgba(45, 157, 188, 0.5)",
            width: 160,
            height: 150,
          },
        };

        const newNode = {
          id: strID,
          data: { label: `Сцена ${strID}` },
          position: { x: 10, y: 5 },
          type: "node-with-toolbar",
          targetPosition: "left",
          className: "light",
          style: {
            backgroundColor: "rgba(125, 157, 188, 0.5)",
            width: 140,
            height: 100,
          },
          extent: "parent",
          parentNode: `${strID}-group`,
          parentId: `${strID}-group`,
          draggable: false,
        };

        setNodes((nds) => nds.concat([groupNode, newNode]));
        setEdges((eds) =>
          eds.concat({
            id: strID + Math.random(),
            source: connectingNodeId.current,
            target: strID,
            type: "step",
          })
        );

        dispatch(
          saveGame({
            selectedGameId: game?._id,
            nodes: [...nodes, groupNode, newNode],
            edges: [
              ...edges,
              {
                id: strID + Math.random(),
                source: connectingNodeId.current,
                target: strID,
                type: "step",
              },
            ],
          })
        );
      }
    },
    [
      dispatch,
      edges,
      game?._id,
      nodes,
      screenToFlowPosition,
      setEdges,
      setNodes,
    ]
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      let startRemainingEdges = [];
      let updateNodes = [...game.nodes];

      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          const temp = updateNodes.filter((el) => el.id !== node.id);

          updateNodes = temp;
          startRemainingEdges = [...remainingEdges, ...createdEdges];
          return [...remainingEdges, ...createdEdges];
        }, edges)
      );

      dispatch(
        saveGame({
          selectedGameId: game._id,
          nodes: updateNodes,
          edges: startRemainingEdges,
        })
      );
    },
    [dispatch, edges, game._id, game.nodes, nodes, setEdges]
  );

  const switchComp = (str) => {
    switch (str) {
      case "settings":
        return <Settings />;
      case "scheme":
        return (
          <NodeDeleteContext.Provider value={{ onNodesDelete, nodes, edges }}>
            <ReactFlow
              ref={reactFlowInstance}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onNodesDelete={onNodesDelete}
              onConnect={onConnect}
              onConnectStart={onConnectStart}
              onConnectEnd={onConnectEnd}
              fitView={true}
              className="react-flow-subflows-example"
              nodeTypes={nodeTypes}
            >
              <MiniMap />
              <Controls />
              <Background />
            </ReactFlow>
          </NodeDeleteContext.Provider>
        );
      default:
        return;
    }
  };

  return (
    <div className="wrapper" ref={reactFlowWrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px 10px",
          gap: "10px",
        }}
      >
        <Button
          variant="outlined"
          size="small"
          onClick={() => setViewMode("scheme")}
        >
          Схема проекта
        </Button>

        <Button
          variant="outlined"
          size="small"
          onClick={() => setViewMode("settings")}
        >
          Настройки проекта
        </Button>
        <Button
          variant="contained"
          onClick={() => dispatch(showDialog("delete"))}
          color="error"
          size="small"
        >
          Удалить
        </Button>
      </div>
      {isLoading ? <CircularProgress /> : switchComp(viewMode)}
    </div>
  );
};

function Workarea(props) {
  const { game } = props;

  return (
    <ReactFlowProvider>
      <Flow {...props} game={game} />
    </ReactFlowProvider>
  );
}

export default Workarea;
