import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addNewGame,
  getGame,
  deleteGame,
  saveGame,
  generateKey,
  getHelpMessage,
  loginUser,
  registrationUser,
  getRules,
  loadVideoScene,
  saveGameSettings,
  getInfo,
  confirmBirthday,
  confirmEmailUser,
  getUsersForModerator,
  getGamesForModerator,
  postNews,
  getLinks,
  confirmCodeEmail,
  getInstruction,
} from "../asyncThunk/userActions";

const prodURL = "/api";
const url =
  window.location.hostname === "localhost"
    ? "http://localhost:3004/api"
    : prodURL;

export const getGamesData = createAsyncThunk(
  "user/getGamesData",
  async function (props) {
    const { id } = props;
    const response = await fetch(`${url}/me/${id}`);
    const result = await response.text();

    return JSON.parse(result);
  }
);

const initialState = {
  status: null,
  actionStatus: null,
  data: null,
  messages: [],
  selectedGameId: null,
  showDialog: {
    isOpen: null,
    type: null,
  },
  game: null,
  games: null,
  rules: [],
  info: [],
  instructions: [],
  gamesLinks: [],
  users: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    exitHandle: (state) => {
      state.data = null;
      state.messages = [];
      state.selectedGameId = null;
      state.showDialog = {
        isOpen: null,
        type: null,
      };
      state.game = null;
      state.games = null;
      state.rules = [];
      state.info = [];
      state.gamesLinks = [];
      state.users = [];
      localStorage.removeItem("token");
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    addMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    selectProject: (state, action) => {
      state.selectedGameId = action.payload;
    },
    resetSelectedProject: (state) => {
      state.selectedGameId = null;
      state.game = null;
      state.showDialog = {
        isOpen: false,
        type: null,
      };
    },
    closeDialog: (state) => {
      state.showDialog = {
        isOpen: false,
        type: null,
      };
    },
    showDialog: (state, action) => {
      state.showDialog = {
        isOpen: true,
        type: action.payload,
      };
    },
    showNotifications: (state) => {
      if (state.data.notifications.length) {
        state.messages = [...state.data.notifications.length];
      } else {
        state.messages = [
          ...state.messages,
          {
            type: "info",
            message: "Новых уведомлений нет",
          },
        ];
      }
    },
    hideDialog: (state) => {
      state.showDialog = false;
    },
    addNode: (state, action) => {
      const { data } = action.payload;
      state.game.nodes = [...state.game.nodes, ...data];
    },
    addEdges: (state, action) => {
      const { data } = action.payload;
      state.game.edges = [...state.game.edges, ...data];
    },
  },
  extraReducers: (build) => {
    build.addCase(getGamesData.pending, (state) => {
      state.status = "loading";
      state.games = null;
    });
    build.addCase(getGamesData.fulfilled, (state, action) => {
      state.status = null;
      state.gamesLinks = action.payload.map((el) => {
        return {
          _id: el._id,
          title: el.title,
        };
      });
      state.games = [...action.payload];
      localStorage.setItem("user", action.payload.tgId);
    });
    build.addCase(getGamesData.rejected, (state) => {
      state.status = null;
    });
    build.addCase(addNewGame.fulfilled, (state, action) => {
      state.actionStatus = null;
      // state.messages = [...state.messages, { ...action.payload }];
      state.showDialog = {
        isOpen: false,
        type: null,
      };
      state.selectedGameId = null;
    });
    build.addCase(addNewGame.pending, (state) => {
      state.actionStatus = "loading";
    });
    build.addCase(addNewGame.rejected, (state, action) => {
      state.messages = [...state.messages, action.payload];
    });
    build.addCase(getGame.fulfilled, (state, action) => {
      state.status = null;
      state.game = action.payload;
    });
    build.addCase(getGame.pending, (state) => {
      state.status = "loading";
    });
    build.addCase(getGame.rejected, (state) => {
      state.status = null;
    });
    build.addCase(saveGame.fulfilled, (state, action) => {
      state.game = action.payload;
    });
    build.addCase(deleteGame.pending, (state) => {
      state.actionStatus = "loading";
    });
    build.addCase(deleteGame.fulfilled, (state, action) => {
      state.messages = [...state.messages, action.payload];
      state.showDialog = {
        isOpen: false,
        type: null,
      };
      state.actionStatus = null;
      state.game = null;
      state.selectedGameId = null;
    });
    build.addCase(deleteGame.rejected, (state, action) => {
      state.messages = [...state.messages, action.payload];
      state.actionStatus = null;
    });
    build.addCase(generateKey.fulfilled, (state, action) => {
      if (action.payload.type === "error") {
        state.messages = [...state.messages, action.payload];
      } else {
        state.data = action.payload;
      }
    });
    build.addCase(getHelpMessage.fulfilled, (state, action) => {
      state.messages = [
        ...state.messages,
        {
          type: "help",
          message: action.payload.description,
        },
      ];
    });
    build.addCase(loginUser.fulfilled, (state, action) => {
      state.status = null;

      if (action.payload.type === "error") {
        state.messages = [action.payload];
      } else {
        state.data = action.payload.user;

        // state.gamesLinks = action.payload.user.games.map((el) => {
        //   return {
        //     _id: el._id,
        //     title: el.title,
        //   };
        // });
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("rememberUser", true);
      }
    });
    build.addCase(registrationUser.fulfilled, (state, action) => {
      state.messages = [action.payload];
    });
    build.addCase(registrationUser.rejected, (state, action) => {
      state.messages = [
        {
          type: "error",
          message: action.payload.message,
        },
      ];
    });
    build.addCase(getRules.fulfilled, (state, action) => {
      state.rules = action.payload;
    });
    build.addCase(loadVideoScene.fulfilled, (state, action) => {
      state.game = action.payload;
    });
    build.addCase(saveGameSettings.fulfilled, (state, action) => {
      state.game = action.payload;
      state.messages = [
        ...state.messages,
        {
          type: "info",
          message: "Изменения сохранены",
        },
      ];
    });
    build.addCase(getLinks.fulfilled, (state, action) => {
      state.gamesLinks = [...action.payload];
    });
    build.addCase(getInfo.fulfilled, (state, action) => {
      state.info = action.payload;
    });
    build.addCase(confirmBirthday.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    build.addCase(confirmEmailUser.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    build.addCase(confirmCodeEmail.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    build.addCase(getGamesForModerator.fulfilled, (state, action) => {
      state.games = action.payload;
    });
    build.addCase(getUsersForModerator.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    build.addCase(postNews.fulfilled, (state, action) => {
      state.info = action.payload;
    });
    build.addCase(getInstruction.fulfilled, (state, action) => {
      state.instructions = action.payload;
    });
  },
});

export const userSlice = slice.actions;
export default slice.reducer;
